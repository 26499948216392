// extracted by mini-css-extract-plugin
export var dropDown = "BasicRegistration-module--dropDown--qsThu";
export var dropDownChevron = "BasicRegistration-module--dropDownChevron--W1DKz";
export var formCheckInput = "BasicRegistration-module--form-check-input--VU3iP";
export var icon = "BasicRegistration-module--icon--cKRQL";
export var inputField = "BasicRegistration-module--inputField--GaNO7";
export var inputFieldBasic = "BasicRegistration-module--inputFieldBasic--dR27G";
export var inputLabel = "BasicRegistration-module--inputLabel--TkYpU";
export var inputLabelBasic = "BasicRegistration-module--inputLabelBasic--Ulsw6";
export var newsletterSwitch = "BasicRegistration-module--newsletterSwitch--zPAlr";
export var submitButton = "BasicRegistration-module--submitButton--mwagQ";
export var submitButtonBasic = "BasicRegistration-module--submitButtonBasic--uDUGe";