import * as React from "react";
import {useState} from "react";
import Layout from "../../components/Navigation/layout";
import {Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import {formHeading, loginBanner, loginImg, vendorLink, vendorLinkHolder} from './index.module.scss';
import BasicRegistration from "../../components/Registration/BasicRegistration/BasicRegistration";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import FullscreenLoading from "../../components/FullscreenLoading/FullscreenLoading";

const IndexPage = () => {
    const [loading, setLoading] = useState(false);

    return (
        <Layout pageTitle="Registrierung">
            {loading ?
                <FullscreenLoading/> : null
            }
            <div className={loginBanner}>
                <div className="container h-100 d-flex flex-column">

                    <h2 className={formHeading + " py-4"}> Servus und Hallo! </h2>
                    <BasicRegistration loadingCallback={setLoading} basic={true}/>
                    <div className={vendorLinkHolder}>
                        <Link to="/register/vendor" className={vendorLink}>
                            <span>Als Händler*in bewerben?</span>
                        </Link>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default IndexPage;
