import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './BasicRegistration.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/pro-solid-svg-icons";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
// @ts-ignore
import * as mainStyle from "../../../theme/main.module.scss";
import {Client, handleApiError} from "../../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {toast} from "react-toastify"; // import Font Awesome CSS
import {setKey} from "../../../stores/auth/authSlice";
import {setMember, setMemberID, setVendorID} from "../../../stores/member/memberSlice";
import {navigate} from "gatsby";

const mapState = ({member, auth}) => ({
    member, auth
})

const mapDispatch = {
    setKey,
    setMember,
    setMemberID,
    setVendorID
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    onComplete?(): void,
    loadingCallback(loadingState): void,
    basic?: boolean,
    checkout?: boolean
}

interface FormInputs {
    FirstName: string,
    Surname: string,
    Email: string,
    Password: string,
    PasswordRetype: string,
    Origin: string,
    UserGivenOrigin: string,
    Street: string,
    StreetNumber: string,
    ZIP: string,
    City: string,
    Land: string,
    Salutation: string,
    NameTitle: string,
    Birthdate: string
}

const schema = yup.object().shape({
    FirstName: yup.string().required('Bitte gib einen Vornamen an.'),
    Surname: yup.string().required('Bitte gib einen Nachnamen an.'),
    Email: yup.string().required('Bitte gib eine E-Mail Adresse an.').email('Die angegebene E-Mail Adresse ist ungültig'),
    Password: yup.string().required('Bitte gib ein Passwort ein.').min(8, 'Das Passwort muss mindestestens 8 Zeichen lang sein.'),
    PasswordRetype: yup.string().required('Bitte wiederhole das Passwort.').oneOf([yup.ref('Password'), null], 'Die Passwörter stimmen nicht überein.'),
    Origin: yup.string().optional(),
    UserGivenOrigin: yup.string().optional(),
    Street: yup.string().optional(),
    StreetNumber: yup.string().optional(),
    ZIP: yup.string().optional(),
    City: yup.string().optional(),
    Land: yup.string().optional(),
    Salutation: yup.string().optional(),
    NameTitle: yup.string().optional(),
    Birthdate: yup.string().optional(),
})

const BasicRegistration: FC<Props> = (props) => {
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });
    const [socialSelection, setSocialSelection] = useState('Instagram');
    const [newsletterStatus, setNewsletterStatus] = useState(false);
    const [newsletterText, setNewsletterText] = useState('');
    const [emailExists, setEmailExists] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setNewsletterText(res.data[0].NewsletterText);
        }).catch(handleApiError);
    })

    useEffect(() => {
        console.log(emailExists)
    }, [emailExists])

    useEffect(()=> {
        if (props.checkout && props.loadingCallback) {
            props.loadingCallback(false);
        }
        }, [])

    function onSubmit(data: FormInputs) {
        if(!props.checkout && props.loadingCheckout) {
            props.loadingCheckout(true);
        }

        Client.Member.checkEmailExists(data.Email).then((emailExistsResponse) => {

            if (emailExistsResponse.data.message === '1') {
                if (props.loadingCallback) {
                    props.loadingCallback(false);
                }

                setEmailExists('Diese E-Mail Adresse wird bereits verwendet.');


            } else {
                console.log("nooooo")
                Client.Member.register(
                    'user',
                    data.FirstName,
                    data.Surname,
                    data.Email,
                    data.Password,
                    data.UserGivenOrigin ?? data.Origin,
                    newsletterStatus ?? false
                ).then((res) => {
                    toast.success('Erfolgreich registriert! 🎉');
                    Client.Raw.post('Member/authorize', {
                        Email: data.Email,
                        Password: data.Password
                    }).then((resAuth) => {
                        let key = resAuth.data.Key;
                        let memberID = resAuth.data.MemberID;
                        props.setKey(resAuth.data.Key);
                        props.setMember(resAuth.data);
                        props.setMemberID(memberID);
                        props.setVendorID(resAuth.data.VendorID);
                        if (!props.checkout) {
                            if (data.Land && data.ZIP && data.City && data.Street && data.StreetNumber) {
                                Client.Auth.authPostNoID(key, 'Address', {
                                    MemberID: memberID,
                                    Country: data.Land ?? '',
                                    PostalCode: data.ZIP ?? '',
                                    City: data.City ?? '',
                                    Street: data.Street ?? '',
                                    Number: data.StreetNumber ?? '',
                                    Salutation: data.Salutation ?? '',
                                    NameTitle: data.NameTitle ?? '',
                                    Birthdate: data.Birthdate ?? '',
                                }).then((res) => {
                                    Client.Auth.authPut(key, 'Member', memberID, {
                                        LastShippingAddressID: res.data.ID,
                                        LastBillingAddressID: res.data.ID
                                    }).then(() => {
                                        toast.success('Eingeloggt. Servus ' + data.FirstName);
                                        if (props.basic && !props.checkout) {
                                            navigate('/user');
                                        } else {
                                            props.loadingCallback(false);
                                            props.onComplete();
                                        }
                                    }).catch(handleApiError)
                                }).catch(handleApiError);
                            }
                            toast.success('Eingeloggt. Servus ' + data.FirstName);
                            if (props.basic && !props.checkout) {
                                navigate('/user');
                            } else {
                                props.loadingCallback(false);
                                props.onComplete();
                            }
                        }
                        props.loadingCallback(false);
                        props.onComplete();
                    }).catch(handleApiError)
                }).catch(handleApiError);
            }
        }).catch(handleApiError);
    }


    return (
        <>
            {!props.basic && !props.checkout ?
                <h2 className="mb-3"> Benutzer </h2> : null
            }
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col lg={6} md={6} xs={6}>

                        <Form.Group controlId="formSalutation" className="mb-2">
                            <Form.Label className={style.inputLabelBasic}>Anrede</Form.Label>
                            <div className={style.dropDownChevron}>
                                <Form.Control as="select"
                                              className={style.dropDown} {...register('Salutation')}>
                                    <option selected={true} disabled={true} value={""}> </option>
                                    <option value={"Herr"}> Herr</option>
                                    <option value={"Frau"}> Frau</option>
                                </Form.Control>
                                <Form.Text className="errorMsg">
                                    {errors.Salutation?.message}
                                </Form.Text>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={6} xs={6}>
                        <Form.Group controlId="formNameTitle" className="mb-2">
                            <Form.Label className={style.inputLabelBasic}>Titel</Form.Label>
                            <Form.Control type="text"
                                          className={style.inputField} {...register('NameTitle')}/>
                            <Form.Text className="errorMsg">
                                {errors.NameTitle?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={props.basic || props.checkout ? 6 : 12} md={6} xs={6}>
                        <Form.Group controlId="formFirstName" className="mb-2">
                            <Form.Label className={props.basic ? style.inputLabelBasic : style.inputLabel}> Dein
                                Vorname*</Form.Label>
                            <Form.Control type="text"
                                          className={props.basic ? style.inputFieldBasic : style.inputField} {...register('FirstName')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.FirstName?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={props.basic || props.checkout ? 6 : 12} md={6} xs={6}>
                        <Form.Group controlId="formLastName">
                            <Form.Label className={props.basic ? style.inputLabelBasic : style.inputLabel}> Dein
                                Nachname*</Form.Label>
                            <Form.Control type="text"
                                          className={props.basic ? style.inputFieldBasic : style.inputField} {...register('Surname')} />
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.Surname?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={6} md={6}>
                        <Form.Group controlId="formEmail">
                            <Form.Label
                                className={props.basic ? style.inputLabelBasic : style.inputLabel}> Deine E-Mail
                                Adresse*</Form.Label>
                            <Form.Control type="email"
                                          className={props.basic ? style.inputFieldBasic : style.inputField} {...register('Email')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.Email?.message}
                                {emailExists}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={props.basic || props.checkout ? 6 : 12} md={6} xs={6}>
                        <Form.Group controlId="formPassword">
                            <Form.Label
                                className={props.basic ? style.inputLabelBasic : style.inputLabel}> Passwort*</Form.Label>
                            <Form.Control type="password"
                                          className={props.basic ? style.inputFieldBasic : style.inputField} {...register('Password')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.Password?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={props.basic || props.checkout ? 6 : 12} md={6} xs={6}>
                        <Form.Group controlId="formPasswordRetype">
                            <Form.Label className={props.basic ? style.inputLabelBasic : style.inputLabel}>
                                Passwort wiederholen*
                            </Form.Label>
                            <Form.Control type="password"
                                          className={props.basic ? style.inputFieldBasic : style.inputField} {...register('PasswordRetype')}/>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.PasswordRetype?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col lg={props.basic || props.checkout ? 6 : 12} md={6} xs={props.basic || props.checkout ? 12 : 12}>
                        <Form.Group controlId="formLastName">
                            <Form.Label className={props.basic ? style.inputLabelBasic : style.inputLabel}>Wie
                                wurdest
                                du auf uns aufmerksam?</Form.Label>
                            <div className={style.dropDownChevron}>
                                <Form.Control as="select" className={style.dropDown} {...register('Origin')}
                                              onChange={(event) => setSocialSelection(event.target.value)}>
                                    <option selected={true} disabled={true}>Bitte auswählen (optional)</option>
                                    <option> Instagram</option>
                                    <option> Facebook</option>
                                    <option> Werbung</option>
                                    <option> Freunde, Bekannte, Familie</option>
                                    <option> Anderes...</option>
                                </Form.Control>
                            </div>
                            <Form.Text className={mainStyle.errorMsg}>
                                {errors.Origin?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    {socialSelection === 'Anderes...' ?
                        <Col lg={12} md={6} xs={6}>
                            <Form.Group controlId="123">
                                <Form.Label className={props.basic ? style.inputLabelBasic : style.inputLabel}>Bitte
                                    erläutere, wie du auf uns aufmerksam geworden bist:</Form.Label>
                                <Form.Control type="text"
                                              className={props.basic ? style.inputFieldBasic : style.inputField}
                                              defaultValue={""} {...register('UserGivenOrigin')}/>
                                <Form.Text className={mainStyle.errorMsg}>
                                    {errors.UserGivenOrigin?.message}
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        : null
                    }
                    <Col lg={6} md={6} xs={6}>
                        <Form.Group controlId="formBirthdate" className="mb-2">
                            <Form.Label className={style.inputLabelBasic}>Geburtsdatum</Form.Label>
                            <Form.Control type="text"
                                          className={style.inputField} {...register('Birthdate')}/>
                            <Form.Text className="errorMsg">
                                {errors.Birthdate?.message}
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    {props.basic ?
                        <>
                        {/*<hr style={{color: '#fff'}} className={"mt-3"}/>
                            <p style={{color: '#fff'}}>Um deine regionalen HändlerInnen zu unterstützen, teile
                                uns bitte
                                deine Adresse mit,
                                damit wir dir automatisch die Produkte aus deiner Region präsentieren können.</p>*/}
                            {/*<Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formStreet" className="mb-2">
                                    <Form.Label className={style.inputLabelBasic}>Straße</Form.Label>
                                    <Form.Control type="text"
                                                  className={style.inputField} {...register('Street')}/>
                                    <Form.Text className="errorMsg">
                                        {errors.Street?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formHouseNr">
                                    <Form.Label className={style.inputLabelBasic}>Hausnummer</Form.Label>
                                    <Form.Control type="text"
                                                  className={style.inputField} {...register('StreetNumber')}/>
                                    <Form.Text className="errorMsg">
                                        {errors.StreetNumber?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formZip">
                                    <Form.Label className={style.inputLabelBasic}>PLZ</Form.Label>
                                    <Form.Control type="text"
                                                  className={style.inputField} {...register('ZIP')}/>
                                    <Form.Text className="errorMsg">
                                        {errors.ZIP?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formOrt">
                                    <Form.Label className={style.inputLabelBasic}>Ort</Form.Label>
                                    <Form.Control type="text"
                                                  className={style.inputField} {...register('City')}/>
                                    <Form.Text className="errorMsg">
                                        {errors.City?.message}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <Form.Group controlId="formRegion">
                                    <Form.Label className={style.inputLabelBasic}>Land</Form.Label>
                                    <div className={style.dropDownChevron}>
                                        <Form.Control as="select"
                                                      className={style.dropDown} {...register('Land')}>
                                            <option value={"AT"}> Österreich</option>
                                        </Form.Control>
                                        <Form.Text className="errorMsg">
                                            {errors.Land?.message}
                                        </Form.Text>
                                    </div>
                                </Form.Group>
                            </Col>*/}
                            <Col lg={12} className={'mt-3'}>
                                <Form.Group className={style.newsletterSwitch} controlId="formNewsletter">
                                    <Form.Label className={style.inputLabelBasic}>{newsletterText}</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        onChange={(e) => setNewsletterStatus(!newsletterStatus)}
                                        id="custom-switch"
                                        checked={newsletterStatus}
                                        label={'Zum Newsletter anmelden'}
                                    />
                                    <Form.Text className={mainStyle.errorMsg}>

                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </> : null
                    }
                    {props.checkout ?
                        <Col lg={12} className={'mt-3'}>
                            <Form.Group className={style.newsletterSwitch} controlId="formNewsletter">
                                <Form.Label className={style.inputLabelBasic}>{newsletterText}</Form.Label>
                                <Form.Check
                                    type="checkbox"
                                    onChange={(e) => setNewsletterStatus(!newsletterStatus)}
                                    id="custom-switch"
                                    checked={newsletterStatus}
                                    label={'Zum Newsletter anmelden'}
                                />
                                <Form.Text className={mainStyle.errorMsg}>

                                </Form.Text>
                            </Form.Group>
                        </Col>
                        :
                        null
                    }
                    <Col lg={props.basic ? 12 : 12}>
                        <Button type={"submit"}
                                className={props.basic ? style.submitButtonBasic : style.submitButton}>
                            <FontAwesomeIcon icon={faUserCircle} className={style.icon}/>
                            Jetzt registrieren
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default connector(BasicRegistration);
